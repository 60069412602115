<template>
  <main class="d-flex w-100 bg-image">
    <!-- Section -->
    <div class="container d-flex flex-column">
      <div class="row vh-100">
        <div class="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
          <div class="d-table-cell align-middle">
            <div class="card">
              <div class="card-header border-bottom-0 bg-gray-custom">
                <div class="text-center mt-4">
                  <img
                    src="@assets/images/logo.png"
                    alt="Coopetro"
                    class="img-fluid w-75"
                  />
                  <h1 class="h3 mt-3">Sistema Actualización de Datos</h1>
                </div>
              </div>
              <div class="card-body p-3 bg-gray-custom">
                <div class="m-sm-4">
                  <AuthLoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AuthLoginForm from '@components/auth/AuthLoginForm.vue'

export default {
  name: 'AuthLogin',
  components: {
    AuthLoginForm,
  },
}
</script>
