<template>
  <form @submit.prevent="formSubmit">
    <!-- Form -->
    <div class="form-group mb-3">
      <div class="input-group">
        <span
          class="input-group-text"
          id="basic-addon1"
          :class="{ 'is-invalid': $v.user.username.$error }"
        >
          <span class="fas fa-user"></span>
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Nro Documento"
          v-model.trim="$v.user.username.$model"
          :class="{ 'is-invalid': $v.user.username.$error }"
        />
      </div>
      <div
        class="invalid-feedback"
        v-if="$v.user.username.$dirty && !$v.user.username.required"
        >Campo requerido</div
      >
    </div>
    <!-- End of Form -->
    <div class="form-group mb-3">
      <!-- Form -->
      <div class="form-group mb-4">
        <div class="input-group">
          <span
            class="input-group-text"
            id="basic-addon2"
            :class="{ 'is-invalid': $v.user.password.$error }"
          >
            <span class="fas fa-key"></span>
          </span>
          <input
            type="password"
            class="form-control"
            placeholder="Contraseña"
            v-model.trim="$v.user.password.$model"
            :class="{ 'is-invalid': $v.user.password.$error }"
          />
        </div>
        <div
          class="invalid-feedback"
          v-if="$v.user.password.$dirty && !$v.user.password.required"
          >Campo requerido</div
        >
      </div>
      <!-- End of Form -->
      <div class="d-flex justify-content-between align-items-top mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="user.remember"
          />
          <label class="form-check-label mb-0"> Recordarme </label>
        </div>
        <div>
          <router-link
            :to="{ name: 'forgot_password' }"
            class="small text-right"
            >¿Olvidaste tu contraseña?</router-link
          >
        </div>
      </div>
    </div>
    <div class="d-grid gap-1 text-center mt-3">
      <template v-if="!loading">
        <button type="submit" class="btn btn-custom2">INGRESAR</button>
      </template>
      <template v-else>
        <button class="btn btn-custom2 disabled"
          ><i class="fa fa-spinner fa-spin"></i> Cargando</button
        >
      </template>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { successRequest, errorRequest } from '@helpers'

export default {
  name: 'AuthLoginForm',
  data: function () {
    return {
      user: {
        username: '',
        password: '',
        remember: false,
      },
      loading: false,
    }
  },
  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
    }),
  },
  created() {
    if (this.loggedIn) this.$router.push({ name: 'loading' })
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async formSubmit() {
      this.$v.$touch()
      this.loading = true
      if (!this.$v.$invalid) {
        this.login(this.user).then(
          (data) => {
            this.loading = false
            successRequest(data, this.$swal)
            return this.$router.push({ name: 'loading' })
          },
          (error) => {
            this.loading = false
            return errorRequest(error, this.$swal)
          }
        )
      }
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
